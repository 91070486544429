<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-toolbar elevation="2" style="background: #f5f5f5">
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    Shareable Product
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pr-5 pb-5 pt-5">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                </v-toolbar>

                <v-data-table
                  :headers="SellerHeader"
                  :items="orders"
                  :search="searchseller"
                  sort-by="id"
                  class="elevation-1"
                  style="background: #f5f5f5 elevation-1"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      style="padding-left: 0px !important"
                      text
                      @click="viewDetail(item)"
                      >Detail</v-btn
                    >
                  </template>

                  <template v-slot:item.sharemother="{ item }">
                    <v-btn text @click="ShareMS(item)" color="primary">
                      Share
                    </v-btn>
                  </template>
                </v-data-table>
              </template>
            </v-col>
          </v-row>
          <!-- 
          <v-dialog
            v-model="dialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card
                  class="mx-auto mt-12"
                  elevation="0"
                  v-if="
                    !(
                      Object.keys(sellerPdetails).length === 0 &&
                      sellerPdetails.constructor === Object
                    )
                  "
                >
                  <v-row>
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                      v-if="sellerPdetails.images.length > 0"
                    >
                      <v-carousel-item
                        v-for="(slide, i) in sellerPdetails.images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            sellerPdetails.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Merchant Name :
                          <span class="font-weight-regular">{{
                            sellerPdetails.seller_name
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            sellerPdetails.title
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            sellerPdetails.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ sellerPdetails.brand }}
                          </span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular">{{
                            sellerPdetails.warranty
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Quantity :
                          <span class="font-weight-regular">{{
                            sellerPdetails.quantity
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"> Unit : {{ sellerPdetails.unit }} </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            sellerPdetails.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            sellerPdetails.shipping_country
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Discount Type :
                          <span class="font-weight-regular">{{
                            sellerPdetails.discount_type
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Discount Amount :
                          <span class="font-weight-regular">{{
                            sellerPdetails.discount_amount
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Discount Validity Till :
                          <span class="font-weight-regular">{{
                            sellerPdetails.discount_end_date
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Price :
                          <span class="font-weight-regular">
                            {{ sellerPdetails.new_price }}</span
                          >
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Old Price :
                          <span class="font-weight-regular">{{
                            sellerPdetails.old_price
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Point :
                          <span class="font-weight-regular">{{
                            sellerPdetails.point
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Point Validity Till :
                          <span>{{ sellerPdetails.point_end_date }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Properties : <span></span
                          >{{ sellerPdetails.properties }}
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ sellerPdetails.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(
                              itemfeatures, index
                            ) in sellerPdetails.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div
                          class="d-flex flex-row"
                          v-if="sellerPdetails.specification.colors.length > 0"
                        >
                          Color :
                          <p
                            class="ml-5"
                            v-for="(itemcolor, index) in sellerPdetails
                              .specification.colors"
                            :key="index"
                          >
                            {{ itemcolor }}
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        <div
                          class="d-flex flex-row"
                          v-if="sellerPdetails.specification.sizes.length > 0"
                        >
                          Size :

                          <p
                            class="ml-5"
                            v-for="(itemsize, index) in sellerPdetails
                              .specification.sizes"
                            :key="index"
                          >
                            {{ itemsize }}
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-card>
                          <v-card-text class="pt-0">
                            <v-card-title class="py-2 px-0 font-weight-bold"
                              >Price For Shared Product</v-card-title
                            >
                            <v-divider></v-divider>
                            <v-simple-table fixed-header>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-center">Quantity</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Purchase Price</th>
                                
                                  </tr>
                                </thead>
                                <tbody align="center">
                                  <tr
                                    v-for="(item, index) in specpricearray"
                                    :key="index"
                                  >
                                    <td class="text-center">
                                      {{ item.quantity }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.status }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.purchase_price }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-card>
          </v-dialog> -->

          <!-- This Dialog for Share Specification and view Details -->

          <v-dialog
            v-model="shareowndialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card class="mx-auto mt-12" elevation="0">
                  <v-row
                    v-if="
                      child_details.product_images &&
                      child_details.product_images.length > 0
                    "
                  >
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(slide, i) in child_details.product_images"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            child_details.product_id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Specification ID :
                          <span class="font-weight-regular">{{
                            child_details.id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          SKU :
                          <span class="font-weight-regular">{{
                            child_details.product_code.SKU
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            child_details.product_data.title
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">
                          Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Sub Sub Category :
                          <span class="font-weight-regular">{{
                            child_details.category_data.sub_sub_category
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ child_details.product_data.brand }}
                          </span>
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Warrenty :
                          <span class="font-weight-regular"
                            >{{ child_details.warranty }}
                            {{ child_details.warranty_unit }}</span
                          >
                        </p>
                      </v-col>

                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Unit :
                          <span class="font-weight-regular">{{
                            child_details.unit
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            child_details.product_data.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            child_details.product_data.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ child_details.product_data.description }}
                      </v-col>

                      <v-col cols="12">
                        <div>
                          <p class="font-weight-medium">Key Features :</p>

                          <li
                            class="mt-5"
                            v-for="(itemfeatures, index) in child_details
                              .product_data.key_features"
                            :key="index"
                          >
                            {{ itemfeatures }}
                          </li>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        Color :

                        {{ child_details.color }}
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        Size :

                        {{ child_details.size }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>

              <v-card>
                <v-card-text class="pt-0">
                  <v-card-title class="py-2 px-0 font-weight-bold"
                    >Price Assign For Share Product</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Quantity</th>
                          <th class="text-left">Status</th>
                          <th class="text-center">Purchase Price</th>
                          <th class="text-center">Selling Price</th>
                          <!-- <th class="text-left">Action</th> -->
                        </tr>
                      </thead>
                      <tbody align="center">
                        <tr
                          v-for="(item, index) in child_details.specprice"
                          :key="index"
                        >
                          <td class="text-left">
                            <v-form>
                              <v-text-field
                                v-if="index"
                                outlined
                                type="number"
                                style="max-width: 100px; height: 50px"
                                class="mt-2"
                                flat
                                dense
                                v-model="item.quantity"
                              ></v-text-field>
                              <p v-else>1</p>
                            </v-form>
                          </td>
                          <td class="text-left">{{ item.status }}</td>

                          <td>
                            <v-form>
                              <v-text-field
                                outlined
                                type="number"
                                style="max-width: 99px; height: 50px"
                                class="mt-2"
                                flat
                                dense
                                v-model="item.purchase_price"
                              ></v-text-field>
                            </v-form>
                          </td>
                          <td>
                            <v-form>
                              <v-text-field
                                outlined
                                type="number"
                                style="max-width: 100px; height: 50px"
                                class="mt-2"
                                flat
                                dense
                                v-model="item.selling_price"
                              ></v-text-field>
                            </v-form>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>

                <v-card-actions>
                  <v-row>
                    <v-col cols="5" class="d-flex flex-row">
                      <p class="pt-5 px-2 font-weight-medium">MRP:</p>
                      <v-text-field
                        outlined
                        type="number"
                        style="max-width: 150px; height: 50px"
                        class="mt-2"
                        flat
                        dense
                        v-model="mrp"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="d-flex flex-row mt-2">
                      <!-- <v-btn elevation="0" color="#854FFF" class="pt-2" @click="UpdateShare">
                      Update And Share
                    </v-btn> -->
                      <v-btn elevation="0" color="red" @click="Cancelshare">
                        Cancel
                      </v-btn>
                      <v-btn
                        elevation="0"
                        color="primary"
                        @click="SaveShare(child_details)"
                      >
                        Share
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-dialog>

          <!-- Mother Site Share for this product -->
          <v-dialog v-model="ShareProductmodel" width="750px">
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                  >Product Name: {{ product_title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-divider></v-divider>
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Assign For Share Product</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Quantity</th>
                        <th class="text-left">Status</th>
                        <th class="text-center">Purchase Price</th>
                        <th class="text-center">Selling Price</th>
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr v-for="(item, index) in specprice" :key="index">
                        <td class="text-left">
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                            ></v-text-field>
                          </v-form>
                        </td>
                        <td class="text-left">{{ item.status }}</td>

                        <td>
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 99px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.purchase_price"
                            ></v-text-field>
                          </v-form>
                        </td>
                        <td>
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.selling_price"
                            ></v-text-field>
                          </v-form>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <v-card-actions>
                <v-row>
                  <v-col cols="5" class="d-flex flex-row">
                    <p class="pt-5 px-2 font-weight-medium">MRP:</p>
                    <v-text-field
                      outlined
                      type="number"
                      style="max-width: 150px; height: 50px"
                      class="mt-2"
                      flat
                      dense
                      v-model="mrp"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="d-flex flex-row mt-2">
                    <!-- <v-btn elevation="0" color="#854FFF" class="pt-2" @click="UpdateShare">
                      Update And Share
                    </v-btn> -->
                    <v-btn elevation="0" color="red" @click="Cancelshare">
                      Cancel
                    </v-btn>
                    <v-btn elevation="0" color="primary" @click="SaveShare">
                      Share
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-row>
    </v-card>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    editdialog: false,
    ShareProductmodel: false,
    loading: true,
    shareowndialog: false,
    searchseller: "",

    child_details: {
      mrp: "",
      product_data: {},
      category_data: {},
      product_code: {},
      specprice: [
        {
          quantity: 1,
          status: "Single",
          purchase_price: "",
        },
        {
          quantity: "",
          status: "Minimum",
          purchase_price: "",
        },
        {
          quantity: "",
          status: "Maximum",
          purchase_price: "",
        },
      ],
    },
    SellerHeader: [
      {
        text: "product ID",
        value: "product_data.id",
      },
      { text: "Specification ID", value: "id" },
      { text: "Product Title", value: "product_data.title" },
      { text: "Brand ", value: "product_data.brand" },
      { text: "Color ", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      // { text: "View", value: "actions", sortable: false },
      { text: "", value: "sharemother", sortable: false },
      // { text: 'test', value: 'tes' },
      // { text: "", value: "data-table-expand" },
    ],

    tickets: [],
    contact: {},

    orders: [],

    sellerPdetails: {},
    specpricearray: [
      {
        quantity: 1,
        status: "single",
        purchase_price: "800",
      },
      {
        quantity: "100",
        status: "min",
        purchase_price: "750",
      },
      {
        quantity: "1000",
        status: "max",
        purchase_price: "700",
      },
    ],
  }),

  methods: {
    // initialize() {
    //   axios.get("product/seller_products/").then((response) => {
    //     if (response.data.success) {
    //       this.orders = response.data.data;
    //       this.loading = false;
    //     }
    //   });
    // },

    //This api is for demo testing with child site data
    initialize() {
      axios.get("productdetails/getunshared_spec/").then((response) => {
        this.orders = response.data.data;
        this.loading = false;
      });
    },

    closedialog() {
      this.dialog = false;
    },
    ShareMS(item) {
      this.shareowndialog = true;
      Object.assign(this.child_details, item);
      // console.log("this is child details", this.child_details);
    },
    SaveShare(child_details) {
      let data = {
        MRP: this.mrp,
        data_array: this.child_details.specprice,
      };
      axios
        .post(
          `productdetails/insert_specification_price/${this.child_details.id}/`,
          data
        )
        .then((response) => {
          if (response.data.success) {
            this.orders = this.orders.filter(
              (contact) => contact.id != child_details.id
            );
            this.shareowndialog = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
          } else {
            this.text = "something Want Wrong!";
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },
    closedialog(){

this.shareowndialog=false;
    },

    viewDetail(item) {
      this.dialog = true;
      this.sellerPdetails = item;
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>



<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>